import { createStore } from 'vuex';
import auth from './auth';
import session from './session';

export default createStore({
  modules: {
    auth,
    session,
  },
});
