<template>
  <v-container>
    <v-card>
      <v-card-title>
        <a :href="resource.url" target="_blank" class="title-link">
          <v-icon class="ml-1">mdi-web</v-icon>
          {{ resource.url }}
        </a>
      </v-card-title>
      <br>
      <v-card-text>
        <h3>Description</h3>
        <p>{{ resource.description }}</p>
        <br>
        <div v-if="resource.competitors && resource.competitors.length > 0">
          <h3>Competitors</h3>
          <ul class="competitors-list">
            <li v-for="(competitor, index) in resource.competitors" :key="index">
              <a :href="competitor.url" target="_blank">{{ competitor.name }}</a>
            </li>
          </ul>
        </div>
        <p v-else>None</p>
        <br>
        <div>
          <h3>Keywords</h3>
          <div v-if="resource.keywords && resource.keywords.length > 0">
            <v-chip v-for="(keyword, index) in resource.keywords" :key="index" class="ma-1">
              {{ keyword }}
            </v-chip>
          </div>
          <p v-else>None</p>
        </div>
        <br>
        <div>
          <h3>Author</h3>
          <div v-if="resource.author">
            <ul class="competitors-list">
              <li><b>By:</b> {{ resource.author.author_name }}</li>
              <li><b>Channel:</b> {{ resource.author.channel_display_name }}</li>
              <li><b>Driver:</b> {{ resource.author.driver }}</li>
              <li><b>Created:</b> {{ resource.author.create_at }}</li>
              <li><b>Post:</b> <a :href="`https://${resource.author.driver_url}/${resource.author.team}/pl/${resource.author.post_id}`" target="_blank">Read it</a></li>
            </ul>
          </div>
          <p v-else>None</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style>
.title-link {
  color: inherit;
  text-decoration: none;
}
.competitors-list {
  margin-left: 1em;
  padding-left: 0;
  list-style-type: none;
}

.competitors-list li {
  margin-bottom: 0;
}
</style>

<script>
import { ref, onMounted } from 'vue';
import { fetchResource } from '@/api';


export default {
  setup(props) {
    const resource = ref({});

    const loadResource = async () => {
      try {
        resource.value = await fetchResource(props.id);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(loadResource);

    return {
      resource,
    };
  },
  props: {
    id: String,
  },
};
</script>
