<template>
  <div>
    <div v-if="!isAuthenticated">
      <v-main>
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12" 
              md="6"
            >
              <v-card>
                <v-card-title class="text-h5">Welcome to LinkLoom</v-card-title>
                <v-card-text>
                  <p>
                    It looks like you don't have an account with us yet. To access all the features of LinkLoom, please sign up for a free account.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
    <div v-else>Welcome!</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import { sendParamsToBackend } from '@/api';

export default {
  name: 'LinkLoom',
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'isSignupNeeded']),
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
  },
  async mounted() {
    if (window.location.href.search("token") > 0) {
      await sendParamsToBackend(this.isSignupNeeded, this.authenticate, this.$router);
    }
  },
};
</script>
