import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import HomeView from '../views/HomeView.vue';
import ResourceList from '../views/ResourceList.vue';
import ResourceDetails from '../views/ResourceDetails.vue';
import SignupView from '../views/SignupView.vue';
import Token from '../views/Token.vue';

const routes = [
  {path: '/', name: 'Home', component: HomeView},
  {path: '/token', name: 'Token', component: Token},
  {path: '/signup', name: 'Signup', component: SignupView},
  {path: '/resources', name: 'ResourceList', component: ResourceList, meta: { requiresAuth: true }},
  {path: '/resource/:id', name: 'ResourceDetails', component: ResourceDetails, props: true, meta: { requiresAuth: true }},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ path: '/' });
  } else {
    next();
  }
});


export default router;
