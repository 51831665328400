<template>
  <div v-if="isAuthenticated">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="text-h5">Welcome to LinkLoom, {{ fullName }}!</v-card-title>
          <v-card-text>
            <p>
              We're glad to have you here. LinkLoom is a platform designed to transform the way businesses conduct competitive and technological intelligence.
            </p>
            <p>
              You can start exploring the features by accessing the resources page.
            </p>
            <v-btn
              color="primary"
              :to="{ name: 'ResourceList' }"
            >
              Access Resources
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row>
      <v-col>
        <h1>Welcome to LinkLoom</h1>
        <p>
          A cutting-edge platform designed to transform the way businesses conduct competitive and technological intelligence.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Key Features</h2>
        <ul>
          <li>Automated Link Collection</li>
          <li>Enhanced Contextual Insights</li>
          <li>Daily Digests</li>
          <li>Advanced Search and Filtering</li>
          <li>Interactive Dashboard</li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Why LinkLoom is Important?</h2>
        <p>
          In today's fast-paced business environment, staying ahead of the competition and being at the forefront of technological advancements is crucial for success.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Problem Solving</h2>
        <ul>
          <li>Information Overload</li>
          <li>Contextual Understanding</li>
          <li>Time Efficiency</li>
          <li>Competitive Intelligence</li>
          <li>Technological Awareness</li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'LinkLoomLandingPage',
  computed: {
    ...mapGetters({isAuthenticated: 'auth/isAuthenticated'}),
    ...mapState({sessionData: state => state.session.data}),
    fullName() {
      return this.sessionData ? `${this.sessionData.user.given_name} ${this.sessionData.user.family_name}` : '';
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 2rem;
}

p {
  margin-bottom: 1rem;
}
</style>
