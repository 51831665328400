import router from '@/router';
import { login as apiLogin, logout as apiLogout } from '@/api';

const user = localStorage.getItem('user');
const signup = localStorage.getItem('signup');

export default {
  namespaced: true,
  state: {
    user: user ? user : null,
    signup: signup ? signup : false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    needSignup(state, status) {
      state.signup = status;
    },
  },
  actions: {
    login() {
      apiLogin();
    },
    async authenticate({ commit }, token) {
      if (token) {
        commit('setUser', true);
        commit('needSignup', false);
        localStorage.setItem('user', token);
        localStorage.removeItem('signup');
        this.dispatch('session/loadSessionData');
      } else {
        commit('setUser', null);
        localStorage.removeItem('user');
      }
    },
    async logout({ commit }) {
      await apiLogout();
      commit('setUser', null);
      localStorage.removeItem('user');
      router.push({ name: 'Home' });
    },
    signup({ dispatch, commit }) {
      commit('needSignup', true);
      localStorage.setItem('signup', true);
      dispatch('login');
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    isSignupNeeded(state) {
      return state.signup;
    },
  },
};
