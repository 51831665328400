<template>
  <v-app>
    <v-app-bar :color="color" dark>
      <v-img
        src="@/assets/logo.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-app-bar-title>
        <router-link to="/" class="title-link">LinkLoom</router-link>
      </v-app-bar-title>

      <v-menu offset-y>
        <template v-slot:activator="{ props }">
          <v-btn icon v-if="!isAuthenticated" v-bind="props">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
          <v-img
            v-if="isAuthenticated && sessionData && sessionData.user.picture"
            :src="sessionData.user.picture"
            max-height="40"
            max-width="40"
            contain
            class="mr-2 rounded-circle"
            v-bind="props"
          ></v-img>
        </template>

      <v-card min-width="300">
        <v-list v-if="isAuthenticated">
          <v-list-item
            :prepend-avatar="sessionData.user.picture"
            :title="fullName"
            :subtitle="company"
          >
          </v-list-item>
        </v-list>

        <v-divider v-if="isAuthenticated"></v-divider>
        <v-list style="min-width: 200px">
          <v-list-item v-if="!isAuthenticated" @click="login">
            <v-row align="center" no-gutters>
              <v-col cols="auto">
                <v-icon class="me-2">mdi-login</v-icon>
              </v-col>
              <v-col>Sign in</v-col>
            </v-row>
          </v-list-item>
          <v-divider v-if="!isAuthenticated"></v-divider>
          <v-list-item v-if="!isAuthenticated" @click="signup">
            <v-row align="center" no-gutters>
              <v-col cols="auto">
               <v-icon class="me-2">mdi-account-plus</v-icon>
             </v-col>
              <v-col>Create your free account</v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-if="isAuthenticated" @click="logout">
            <v-row align="center" no-gutters>
              <v-col cols="auto">
                <v-icon class="me-2">mdi-logout</v-icon>
              </v-col>
              <v-col>Logout</v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
      </v-menu>

    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LinkLoom',
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      sessionData: 'session/data'
    }),
    fullName() {
      return this.sessionData ? `${this.sessionData.user.given_name} ${this.sessionData.user.family_name}` : '';
    },
    company() {
      let companyUser = "Personal account";
      if (this.sessionData.user?.hd != undefined) {
        companyUser = this.sessionData.user.hd;
      }
      return companyUser;
    },
  },
  methods: {
    ...mapActions('auth', ['login', 'logout', 'signup']),
  },
  created() {
    if (this.isAuthenticated) {
      this.$store.dispatch('session/loadSessionData');
    }
  },
  data() {
    return {
      color: '#14333b',
    };
  },
};
</script>

<style>
.title-link {
  color: inherit;
  text-decoration: none;
}
</style>
