import store from '@/store';

const API_BASE_URL = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const getAuthorizationHeader = () => {
  const token = localStorage.getItem('user');
  return { 'Authorization': `Bearer ${token}` };
};

export function login() {
  const authUrl = `${API_BASE_URL}/auth/login`;
  window.location.href = authUrl;
}

export const fetchResources = async () => {
  const response = await fetch(`${API_BASE_URL}/api/links/`, {
    headers: getAuthorizationHeader(),
  });
  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      store.dispatch('auth/logout');
    }
    throw new Error('Failed to fetch resources');
  }
  return response.json();
};

export const fetchResource = async (id) => {
  const response = await fetch(`${API_BASE_URL}/api/links/${id}`, {
    headers: getAuthorizationHeader(),
  });
  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      store.dispatch('auth/logout');
    }
    throw new Error('Failed to fetch resource');
  }
  return response.json();
};

export const deleteResource = async (id) => {
  const response = await fetch(`${API_BASE_URL}/api/links/${id}`, {
    method: 'DELETE',
    headers: getAuthorizationHeader(),
  });
  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      store.dispatch('auth/logout');
    }
    throw new Error('Failed to delete resource');
  }
};

export const fetchSessionData = async () => {
  const response = await fetch(`${API_BASE_URL}/auth/session`, {
    method: 'GET',
    credentials: 'include',
    headers: getAuthorizationHeader(),
  });
  if (!response.ok) {
    if (response.status === 401) {
      return { authFailed: true };
    }
    throw new Error('Failed to fetch session data');
  }
  return response.json();
};

export const logout = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/logout`, {
      method: 'POST',
      headers: getAuthorizationHeader(),
    });
    if (!response.ok) {
      throw new Error('Failed to logout');
    }
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export async function sendParamsToBackend(isSignupNeeded, authenticate, router) {
  const currentUrl = window.location.href;
  const params = new URLSearchParams(new URL(currentUrl).search);

  let backendUrl = `${API_BASE_URL}/auth/token/?${params}`;

  if (params.size > 0) {
    try {
      if (isSignupNeeded) {
        backendUrl = `${backendUrl}&signup=true`;
      }
      const response = await fetch(backendUrl, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      if (data.need_signup) {
        if (!isSignupNeeded) {
          localStorage.removeItem('signup');
        }
        router.push({name: 'Token'});
      } else {
        authenticate(data.access_token);
        router.push({name: 'Home'});
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
