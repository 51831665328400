import { fetchSessionData } from '@/api';

export default {
  namespaced: true,
  state: {
    data: null,
  },
  mutations: {
    setSessionData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async loadSessionData({ commit }) {
      try {
        const result = await fetchSessionData();
        if (result.authFailed) {
          commit('setSessionData', null);
          this.dispatch('auth/logout');
        } else {
          commit('setSessionData', result);
        }
      } catch (error) {
        console.error('Error in loadSessionData action:', error);
      }
    },
  },
  getters: {
    data: state => state.data,
  },
};
