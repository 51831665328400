<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title class="text-h5">Sign Up</v-card-title>
              <v-card-text>
                <v-btn
                  color="primary"
                  @click="signInWithGoogle"
                >
                  Sign Up with Google
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'SignUpPage',
  methods: {
    signInWithGoogle() {
      console.log("SIGNUP");
    },
  },
};
</script>

<style scoped>
</style>
