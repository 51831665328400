<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="resources"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.url="{ value }">
        <a target="_blank" :href="value">
          {{value}}
        </a>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <v-btn icon color="blue" @click="viewResource(item.id)" class="me-2">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="removeResource(item.id)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { fetchResources, deleteResource } from '@/api';

export default {
  setup() {
    const router = useRouter();

    const headers = ref([
      { title: 'URL', value: 'url' },
      { title: 'Description', value: 'description' },
      { title: 'Actions', value: 'action', sortable: false },
    ]);
    const resources = ref([]);

    const loadResources = async () => {
      try {
        resources.value = await fetchResources();
      } catch (error) {
        console.error(error);
      }
    };

    const viewResource = (id) => {
      router.push({ name: 'ResourceDetails', params: { id } });
    };

    const removeResource = async (id) => {
      try {
        await deleteResource(id);
        await loadResources();
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(loadResources);

    return {
      headers,
      resources,
      viewResource,
      removeResource,
    };
  },
};
</script>
